import * as types from "../types";

export const idTokenActionsReducer = (
    idToken = null,
    action = { type: types.ID_TOKEN_SET, payload: null },
) => {
    switch (action.type) {
        case (types.ID_TOKEN_SET):
            return action.payload;
        default:
            return idToken;
    }
}

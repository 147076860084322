import * as types from "../types";

export const userActionsReducer = (
    user = null,
    action = { type: types.USER_SET, payload: {} }
) => {
    switch (action.type) {
        case (types.USER_SET):
            return action.payload;
        default:
            return user;
    }
}

import { CognitoJwtVerifier } from "aws-jwt-verify";
import { events } from '../aiops-auth-util';
import store from '../store';
import { setPermissions as setReduxPermissions } from '../store/actions/PermissionsActions';
import { setIdToken } from "../store/actions/IdTokenActions";
import * as rootConfig from '@aiops/root-config';

/**
 * Returns the url to the site to which to send the user to get authorization.
 * 
 * @param region 
 * The AWS region to use in the url.
 * 
 * @param redirectUri 
 * The uri to which AWS should return the user, with an access code as query
 * parameter, after the user signs in.
 * 
 * @param clientId 
 * AWS Client Id.
 * 
 */
export const authSiteUrl = (
    authorizeEndpoint: string,
    redirectUri: string,
    clientId: string,
) => {
    if (!authorizeEndpoint || !redirectUri || !clientId) {
        throw new Error(`authorize endpoint, redirectUri and clientId are all required, got: ${authorizeEndpoint}, ${redirectUri}, ${clientId}`);
    }
    return `${authorizeEndpoint}?identity_provider=Azure-AD&redirect_uri=${redirectUri}&response_type=code&client_id=${clientId}&scope=email+openid+profile`;
}

/**
 * Sets the user's id token and permissions both to null.
 */
export const wipeIdTokenAndPermissions = () => {
    store.dispatch(setIdToken(null));
    store.dispatch(setReduxPermissions(null));
    window.dispatchEvent(new Event(events.AUTH_EVENT_OCCURRED));
    window.dispatchEvent(new Event(events.PERMISSIONS));
}

/**
 * Sets the user's permissions in redux state based on a payload from validating
 * the user's access token.
 * 
 * @param newPermissions 
 * Object that is returned from validating a user's access token (or null, to
 * wipe all permissions).
 */
export const setPermissions = (newPermissions: Record<string, any> | null): void => {
    store.dispatch(setReduxPermissions(newPermissions));
    window.dispatchEvent(new Event(events.PERMISSIONS));
}

/**
 * Returns the result of an AWS Cognito API request to get an access token given
 * an auth code.
 * 
 * @param authCode 
 * The auth code to provide to AWS to get an access token.
 */
export const fetchTokensFromAuthCode = async (authCode: string): Promise<Record<string, any>> => {
    const {
        clientId,
        redirectUrl,
        endpoints,
    } = rootConfig?.getConfig()?.auth || {};

    if (!clientId || !redirectUrl || !endpoints?.tokenFromAuthCode) {
        throw new Error(`clientId, redirectUrl, or endpoints.tokenFromAuthCode missing in auth config. getConfig().auth: ${rootConfig.getConfig()?.auth}`);
    }

    const body = {
        grant_type: "authorization_code",
        client_id: clientId,
        code: authCode,
        redirect_uri: redirectUrl,
    };

    const formBody = Object.keys(body)
        .map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(body[k])}`)
        .join('&');

    const response = await fetch(
        endpoints.tokenFromAuthCode,
        {
            method: 'POST',
            body: formBody,
            headers: {
                "Content-type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
        }
    ).then(async (res) => {
        if (!res.ok) {
            const asObj = await res.json();
            throw new Error(asObj.error);
        }
        return res.json();
    });
    return response;
}

/**
 * Returns a validated payload (object) if access token is valid; null if not.
 * 
 * @param accessToken 
 * Access token to verify.
 */
export const getValidatedPayloadFromAccessToken = async (accessToken: string): Promise<Record<string, any> | null> => {
    const { userPoolId, clientId } = rootConfig?.getConfig()?.auth || {};
    if (!userPoolId || !clientId) {
        throw new Error(`userPoolId or clientId missing in auth config. getConfig().auth: ${rootConfig.getConfig()?.auth}`);
    }

    try {
        // Verifier that expects valid access tokens:
        const verifier = CognitoJwtVerifier.create({
            userPoolId,
            tokenUse: "id",
            clientId,
        });
        const payload = await verifier.verify(accessToken);
        return payload;
    } catch (err) {
        console.error("Token not valid!", err);
        return null;
    }
}

/**
 * Takes a validated payload object as input and returns a user object, or null
 * if the payload is falsey or an empty object.
 * 
 * @param payload 
 * A validated payload object created by the aws-jwt-verify library.
 * 
 * @param lastNameFirst 
 * When true the user's last name (Smith) will be placed first, and the
 * user's first name (John) will be placed second, in the user object's
 * full name field (ie user.fullName will be Smith John instead of John Smith). 
 * False by default.
 * 
 */
export const getUserFromValidatedPayload = (
    payload: Record<string, any> = {},
    lastNameFirst = false,
): Record<string, string> | null => {
    // Return null if there is no validated payload.
    if (!payload || Object.keys(payload).length === 0) {
        return null;
    }

    const firstName = payload.first_name || "";
    const lastName = payload.last_name || "";
    const email = payload.email || "";

    const fullName = lastNameFirst
        ? `${lastName} ${firstName}`
        : `${firstName} ${lastName}`;

    const user = {
        firstName,
        lastName,
        fullName,
        email,
    }

    return user;
}

export const getUserFromKcObject = (
    keycloak: Record<string, any>,
    lastNameFirst: boolean = false,
) => {
    if (!keycloak || Object.keys(keycloak).length === 0) {
        return null;
    }
    const firstName = keycloak.given_name || "";
    const lastName = keycloak.family_name || "";
    const email = keycloak.email || "";

    const fullName = lastNameFirst
        ? `${lastName} ${firstName}`
        : `${firstName} ${lastName}`;

    const user = {
        firstName,
        lastName,
        fullName,
        email,
    }

    return user;
}

/**
 * Returns a list of apps filtered such that all of them should be shown as
 * choices to the user (for example in the app picker app, or in the lists of
 * apps that appear in the header and settings apps).
 * 
 * The returned list is in alphabetical order by app display name.
 * 
 * @param apps 
 * Object where each key is an app id and each value is the app object from the
 * list of app object returned by the root app's getConfig function.
 * 
 */
export const getVisibleAppsFromAppsObj = (apps: Record<string, any>) => {
    const visibleApps = Object.keys(apps)
        .map((key) => {
            const app = apps[key];
            if ((app.hidden && app.group) || (app.hidden === false && !app.group)) {
                const err = `AppObject with id ${app.appId} has a value for hidden ("${app.hidden}") that is not consistent with its grouping property ("${app.group}").`;
                console.error(err);
                throw new Error(err);
            }
            return app;
        })
        .filter((app) => !app.hidden)
        .sort((a, b) => {
            return (a.appName < b.appName) ? -1 : (a.appName > b.appName) ? 1 : 0;
        });
    return visibleApps;
}

/**
 * Takes a list of app objects and returns a list of objects that group the app
 * objects according to their group property. The returned list is sorted in
 * alphabetical order by group name.
 * 
 * Each object in the returned list has a groupingName property, a groupingId 
 * property, and a list of the apps that belong to that group.
 * 
 * Throws an error if any of the app objects do not have a value for group, or
 * if that value is not a string, or the app is hidden (and therefore should not
 * belong to a group).
 * 
 * @param apps 
 * List of app objects each of which must have a string value for 'group', and 
 * must not have a truthy value for 'hidden').
 */
export const getGroupedApps = (apps: any[]) => {
    return apps
        .map((app) => {
            if (!app.group || typeof app.group !== 'string' || app.hidden) {
                const err = `AppObject with id ${app.appId} has no value for group, or it is not a string, or the app is hidden.`;
                console.error(err);
                throw new Error(err);
            }
            return app.group
        })
        .filter((group, idx, arr) => idx === arr.findIndex((g) => g === group)) // Filter duplicates
        .map((g) => ({
            group: g,
            groupingName: g,
            groupingId: g.toUpperCase().split(" ").join("_"),
            apps: apps.filter((app) => app.group === g),
        })).sort((a, b) => {
            return (a.group < b.group) ? -1 : (a.group > b.group) ? 1 : 0;
        });
}

/**
 * Takes a list of app objects and returns the apps that have a settings page.
 * 
 * @param apps 
 * The list of apps to filter for those with settings.
 * 
 */
export const getAppsWithSettings = (apps: any[]) => {
    return apps.filter((app) => app.hasSettings);
}
import store from './store';
import { setIdToken } from './store/actions/IdTokenActions';
import {
    getValidatedPayloadFromAccessToken,
    fetchTokensFromAuthCode,
    authSiteUrl,
    wipeIdTokenAndPermissions,
    setPermissions,
    getUserFromValidatedPayload,
    getVisibleAppsFromAppsObj,
    getGroupedApps,
    getAppsWithSettings,
    getUserFromKcObject,
} from './util';
import * as rootConfig from '@aiops/root-config';
import Keycloak, { KeycloakOnLoad, KeycloakPkceMethod } from 'keycloak-js';
import { setUser } from './store/actions/UserActions';
import { setUpdateAppAccessIsRunning } from './store/actions/updateAppAccessIsRunningActions';
import { setIsKeycloakInitialized } from './store/actions/keycloakInitializedActions';

const PLATFORM_APP_ID = "PLATFORM";

/**
 * Types of events that the auth-util app can emit.
 */
export const events = {
    AUTH_EVENT_PENDING: 'AUTH_EVENT_PENDING',
    AUTH_EVENT_OCCURRED: 'AUTH_EVENT_OCCURRED',
    PERMISSIONS: 'PERMISSIONS_UPDATED',
}

const {
    auth: {
        clientId,
        endpoints: {
            authorize,
            logout,
            checkPermissions,
        },
        redirectUrl,
        realm,
    },
    authMethod,
    appList,
} = rootConfig.getConfig();

const KEYCLOAK_INIT_OPTIONS = {
    url: authorize,
    realm: realm,
    clientId: clientId,
    onLoad: "login-required" as KeycloakOnLoad,
    redirectUri: redirectUrl,
    pkceMethod: "S256" as KeycloakPkceMethod,
}
const keycloak = new Keycloak({
    clientId: KEYCLOAK_INIT_OPTIONS.clientId,
    realm: KEYCLOAK_INIT_OPTIONS.realm,
    url: KEYCLOAK_INIT_OPTIONS.url,
})

export const initKeycloak = async (): Promise<boolean> => {
    window.dispatchEvent(new Event(events.AUTH_EVENT_PENDING));
    try {
        await keycloak.init({
            onLoad: KEYCLOAK_INIT_OPTIONS.onLoad,
            pkceMethod: KEYCLOAK_INIT_OPTIONS.pkceMethod,
            redirectUri: KEYCLOAK_INIT_OPTIONS.redirectUri,
            checkLoginIframe: false,
        }).then((auth) => {
            if (auth) {
                store.dispatch(setIdToken(keycloak.idToken));
                store.dispatch(setIsKeycloakInitialized(true));
                return true;
            } else {
                return false;
            }
        });
    } catch (error) {
        if (error.message === "A 'Keycloak' instance can only be initialized once.") {
            store.dispatch(setIsKeycloakInitialized(true));
            return true;
        }
    } finally {
        window.dispatchEvent(new Event(events.AUTH_EVENT_OCCURRED));
    }
    return false;
}

/**
 * Redirect the user to the external url that checks authentication and access.
 */
export const redirectUserToAuthSite = () => {
    if (authMethod === "COGNITO") {
        if (!authorize || !clientId || !redirectUrl) {
            throw new Error(`authorize endpoint, clientId or redirectUrl missing in auth config. getConfig().auth: ${JSON.stringify(rootConfig.getConfig()?.auth)}`);
        }
    
        window.location.replace(authSiteUrl(authorize, redirectUrl, clientId));
    } else if (authMethod === "KEYCLOAK") {
        keycloak.login();
    }
}

/**
 * Returns true if the user is signed in, false otherwise.
 */
export const userIsSignedIn = async (): Promise<boolean> => {
    const idToken: string | null = store.getState().idToken;

    // If they don't have an id token, they're not signed in.
    if (!idToken && !keycloak.idToken) {
        return false;
    } else if (keycloak.idToken) {
        store.dispatch(setIdToken(keycloak.idToken));
    }

    // If they already have an id token, make sure it's still valid.
    let payload: boolean | Record<string, any>;
    if (authMethod === 'KEYCLOAK') {
        payload = !keycloak.isTokenExpired();
    } else if (authMethod === 'COGNITO') {
        payload = await getValidatedPayloadFromAccessToken(idToken);
    }

    if (payload) {
        return true;
    } else {
        // If an access token existed in redux state but is no longer valid, the
        // user's access has expired. Wipe any saved idToken and permissions.
        wipeIdTokenAndPermissions();
        if (authMethod === "KEYCLOAK") {
            keycloak.logout();
        }
        return false;
    }
}

/**
 * Asynchronously attempts to get a valid access token for the user, given an
 * auth code. Returns no value, regardless of whether a valid access token was
 * successfully retrieved.
 * 
 * Dispatches an AUTH_EVENT_PENDING event when it starts executing and an
 * AUTH_EVENT_OCCURRED event immediately before returning.
 * 
 * @param authCode 
 * The auth code to use to try to get an access token.
 */
export const signInWithAuthCode = async (authCode: string): Promise<void> => {
    window.dispatchEvent(new Event(events.AUTH_EVENT_PENDING));

    try {
        const res = await fetchTokensFromAuthCode(authCode);
        store.dispatch(setIdToken(res?.id_token || null));
    } catch (err) {
        console.error("unable to sign in with auth code: ", err);
        store.dispatch(setIdToken(null));
    }

    window.dispatchEvent(new Event(events.AUTH_EVENT_OCCURRED));
}

/**
 * Signs the user out.
 */
export const signOut = () => {
    if (authMethod === 'KEYCLOAK') {
        wipeIdTokenAndPermissions();
        keycloak.logout();
    } else if (authMethod === 'COGNITO') {
        if (!logout || !clientId || !redirectUrl) {
            throw new Error(`Unable to logout. Auth logout endpoint and/or client Id are missing from getConfig().auth.`);
        }
        wipeIdTokenAndPermissions.bind(window.location.replace(logout))
    }
}

/**
 * Returns the user's permissions, which will be either an object with unknown
 * key/value pairs or null.
 */
export const getPermissions = (): Record<string, any> | null => {
    return store.getState().permissions || null;
}

/**
 * Returns a user object (with details about the user like name, email, etc) or
 * null, if none has been saved and none can be created by decoding the user's
 * id token.
 */
export const getUser = async (): Promise<Record<string, any> | null> => {
    // If there's already a user object in the store, return it.
    const inStore = store.getState().user;
    if (inStore) {
        return inStore;
    }

    // If there's no id token, there's no way to get user info.
    const idToken = store.getState().idToken;
    if (!idToken) {
        return null;
    }
    let userObject: Record<string, string>;    
    if (authMethod === 'KEYCLOAK') {
        userObject = getUserFromKcObject(keycloak.idTokenParsed);
    } else if (authMethod === 'COGNITO') {
        const validatedPayload = await getValidatedPayloadFromAccessToken(idToken);
        if (!validatedPayload) {
            return null;
        }
        userObject = getUserFromValidatedPayload(validatedPayload);
    }

    store.dispatch(setUser(userObject));    
    return userObject;
}

/**
 * Checks the list of apps the user has permission to access and saves it in the
 * auth-util redux state. Returns nothing, regardless of whether permissions
 * were found or not.
 * 
 * Does not check what actions the user can take within a given app - only 
 * whether the user is allowed to access the app.
 */
export const updateAppAccessPermissions = async (): Promise<void> => {
    // List of all app objects, including display name, grouping, path, etc
    if (!appList || !Array.isArray(appList)) {
        throw new Error(`Expected app list to be an array but got: ${appList}`);
    }

    // Object that will be filled with key/value pairs where each key is an
    // appId and each value is an app object
    const apps: any = {};

    // Object with will be filled with key/value pairs where each key is a
    // platform-wide resource and each value is an object with key/value pairs
    // where each key is an action and each value is a boolean.
    let platform: Record<string, any>;

    try {
        // This updateAppAccessIsRunning flag exists to solve a race conditions
        // issue where getPermissions can be called multiple times because
        // multiple auth events are triggered. So if this API call is already in
        // the process of being made, don't make it again. In **theory** the
        // try/catch/finally block should prevent the case that the flag gets
        // stuck and the function is perpetually blocked.
        if (store.getState().updateAppAccessIsRunning) {
            return;
        } else {
            store.dispatch(setUpdateAppAccessIsRunning(true));
        }

        const apiRes = await getAppPermissions(PLATFORM_APP_ID);
        platform = apiRes || {};

        // Figure out the intersection of the set of apps that the root config
        // wants to know about (appList) and the set of apps that the user has
        // access to (apiRes).
        appList.forEach((app) => {
            const permission = apiRes[app.appId];
            if (permission?.access) {
                apps[app.appId] = {
                    ...app,
                    ...permission,
                }
            }
        });
    } catch (error) {
        console.error("Unable to access check app access permissions: ", error);
    } finally {
        store.dispatch(setUpdateAppAccessIsRunning(true));
    }

    const existingPermissions = store.getState().permissions;
    const visibleApps = getVisibleAppsFromAppsObj(apps) || [];
    const visibleAppsByGroup = getGroupedApps(visibleApps) || [];
    const visibleAppsWithSettings = getAppsWithSettings(visibleApps);

    // Set the permissions to be the existing permissions with any existing app
    // list now replaced by the new one.
    setPermissions({
        ...existingPermissions,
        apps,
        platform,
        visibleApps,
        visibleAppsByGroup,
        visibleAppsWithSettings,
    });
}

/**
 * Given an appId and a list of actions, returns an object where each key is an
 * action from the list passed as a parameter, with a corresponding boolean for
 * whether the user is allowed to take that action.
 * 
 * @param appId 
 * Unique id for the app in question.
 * 
 * @param actions 
 * List of actions (strings) for which to check the user's permission. 
 */
export const getAppPermissions = async (
    appId: string,
): Promise<Record<string, any>> => {
    const idToken = store.getState().idToken;
    if (!idToken) {
        throw new Error(`Unable to check app access permissions without id token. Received ${typeof idToken}: ${idToken}`);
    }

    const checkPermissionsEndpoint = checkPermissions
    if (!checkPermissionsEndpoint) {
        throw new Error(`No check permissions endpoint from getConfig().auth.endpoint. getConfig().auth: ${JSON.stringify(rootConfig?.getConfig()?.auth)}}`);
    }

    const req = {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${idToken}`,
        },
    }
    if (authMethod === "COGNITO") {
        req.headers['applicationId'] = appId;
    }

    const apiRes = await fetch(checkPermissionsEndpoint, req)
        .then((res) => {
            if (!res.ok) {
                throw new Error(`Bad request: ${res.status} - ${res.statusText}`);
            }
            return res.json();
        });
    return apiRes;
}

/**
 * Returns the user's idToken if one exists, or null if not. 
 */
export const getIdToken = (): string | null => {
    return store.getState().idToken || null;
}

import * as types from "../types";

export const updateAppAccessIsRunningActionsReducer = (
    updateAppAccessIsRunning = false,
    action = { type: types.UPDATE_APP_ACCESS_IS_RUNNING_SET, payload: false },
) => {
    switch (action.type) {
        case (types.UPDATE_APP_ACCESS_IS_RUNNING_SET):
            return action.payload;
        default:
            return updateAppAccessIsRunning;
    }
}
import * as types from "../types";

export const isKeycloakInitializedReducer = (
  isKeycloakInitialized = false,
  action = { type: types.KEYCLOAK_INITIALIZED_SET, payload: false },
) => {
  switch (action.type) {
    case (types.KEYCLOAK_INITIALIZED_SET):
      return action.payload;
    default:
      return isKeycloakInitialized;
  }
}
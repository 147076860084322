import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { idTokenActionsReducer } from "./reducers/IdTokenReducer";
import { permissionsActionsReducer } from "./reducers/PermissionsReducer";
import { userActionsReducer } from "./reducers/UserReducer";
import { updateAppAccessIsRunningActionsReducer } from "./reducers/updateAppAccessIsRunningReducer";
import { isKeycloakInitializedReducer } from "./reducers/isKeycloakInitializedReducer";

const reducer = combineReducers({
  idToken: idTokenActionsReducer,
  permissions: permissionsActionsReducer,
  user: userActionsReducer,
  updateAppAccessIsRunning: updateAppAccessIsRunningActionsReducer,
  isKeycloakInitialized: isKeycloakInitializedReducer,
});

// If no preloaded state is provided, each reducer will be run with no input,
// and the field will be set to whatever the reducer returns as a default value
// when it receives no input.
// Or you can preload any state you want, as long as it matches the shape of the
// fields specified in the reducer.
const store = configureStore({
  reducer,
  // preloadedState: initialState,
});

export default store;

import * as types from "../types";

export const permissionsActionsReducer = (
    permissions = null,
    action = { type: types.PERMISSIONS_SET, payload: {} }
) => {
    switch (action.type) {
        case (types.PERMISSIONS_SET):
            return action.payload;
        default:
            return permissions;
    }
}
